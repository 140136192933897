import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { mapEdgesToNodes } from '../lib/helpers';
import { breakpoints } from '../theme/mediaQueries';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageMainHeader from '../components/PageMainHeader';
import PortableText from '../components/portableText';
import ContactForm from '../components/Contact/ContactForm';
import PageFooter from '../components/PageFooter';

const ContactPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const { _rawBody: contactText } = data.contact;

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.seoImg} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>

      {contactText ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '4.875rem',
          }}
        >
          <StyledTextContainer>
            <PortableText blocks={contactText} />
          </StyledTextContainer>
        </Container>
      ) : (
        ''
      )}

      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <ContactForm lang={pageContext.locale} />
          </Col>
        </Row>
      </Container>

      <PageFooter></PageFooter>
    </Layout>
  );
};

const StyledTextContainer = styled.section`
  & p {
    text-align: justify;
  }
  @media (min-width: ${breakpoints.md}px) {
    max-width: 35em;
  }
`;

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-badd5592-27a4-5b4f-aa03-dfb710f7beb8" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              seoImg: fluid {
                src
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    contact: sanityContact {
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

export default localize(ContactPage);
